<div class='flex_row mt-4'>
  <p-table #dt [columns]="cols" [value]="drivers">
    <ng-template pTemplate="caption">
      <div style="position: absolute;">
        <button type="button" pButton icon="pi pi-file-o" iconPos="left" label="CSV" (click)="dt.exportCSV()"
          style="margin-right: 0.5em;"></button>
        <button *ngIf="checkPermission('AIRPORT_QUEUE') && isAirportQueueApplicable()" type="button" pButton icon="fa fa-plane"
          iconPos="left" label="Airport Queue" (click)="openQueuePopUp()" style="margin-right: 0.5em;"></button>
        </div>
      <div style="text-align: right">
        <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
          <input  [(ngModel)]="criteria" placeholder="Vehicle Number or Driver Id" 
          pInputText style="width: 15%;" (ngModelChange) = "getDriversOnChange($event)">
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns">
          <th *ngIf="col.isVisible" [ngClass]="col.field=='driverStateString' ? 'expandCol':''">
            <ng-container [ngSwitch]="col.field">
              <ng-container *ngSwitchCase="'hub'">
                Hub
                <p-multiSelect appendTo="body" [options]="hubList" defaultLabel="All"
                  (onChange)="onHubNameChange($event.value)" [style]="{'width':'100%'}"
                  [panelStyle]="{'width': '170px'}"></p-multiSelect>
              </ng-container>
              <ng-container *ngSwitchCase="'vehicleNumber'">
                Vehicle Number
                <p-dropdown  appendTo="body" [options]="vehicleCatagories" defaultLabel="All"
                  (onChange)="onVehicleChange($event.value)" [style]="{'width':'100%'}"
                  [panelStyle]="{'width': '170px'}"></p-dropdown>
                  <!-- <p-dropdown [options]="vehicleCatagories" appendTo="body"  [(ngModel)]="selectedvehicleCategory" defaultLabel="All" [ngStyle]="{width: 'min-content'}"
                  (onChange)="onVehicleChange($event.value)"></p-dropdown> -->
              </ng-container>
              <ng-container *ngSwitchCase="'totalPausedTime'">
                Total Paused Time
                <p-dropdown  appendTo="body" [options]="totalPausedTimeFilters" defaultLabel="All"
                (onChange)="onTotalPausedTimeChange($event.value)" [style]="{'width':'100%'}"
                [panelStyle]="{'width': '170px'}"></p-dropdown>
              </ng-container>
              <ng-container *ngSwitchCase="'pauseTime'">
                Paused Since
              </ng-container>
              <ng-container *ngSwitchCase="'driverStateString'">
                <div class="line">
                  <span class="count redishPink" data-toggle="tooltip" data-placement="top" title="Requested to return">
                    {{ driverOnDutyStats.returnToHubCount }}
                  </span>
                  <span class="count darkRedishPink" data-toggle="tooltip" data-placement="top" title="Enroute to Hub">
                    {{ driverOnDutyStats.enrouteToHubCount }}
                  </span>
                  <span class="count black" data-toggle="tooltip" data-placement="top" title="Online">
                    {{ driverOnDutyStats.onlineCount }}
                  </span>
                  <span class="count lightCoral" data-toggle="tooltip" data-placement="top" title="Paused">
                    {{ driverOnDutyStats.pausedCount }}
                  </span>
                  <span class="count tomato" data-toggle="tooltip" data-placement="top" title="Auto Paused">
                    {{ driverOnDutyStats.autoPausedCount }}
                  </span>
                  <span class="count grey" data-toggle="tooltip" data-placement="top" title="Checked In">
                    {{ driverOnDutyStats.vehicleSelectCount }}
                  </span>
                </div>
                <div class="line" style="margin-bottom: 10px;">
                  <span class="count violet" data-toggle="tooltip" data-placement="top" title="Waiting for Key">
                    {{ driverOnDutyStats.waitingForKeyCount }}
                  </span>
                  <span class="count teal" data-toggle="tooltip" data-placement="top" title="Waiting for Online">
                    {{ driverOnDutyStats.waitingForOnlineCount }}
                  </span>
                  <span class="count pink" data-toggle="tooltip" data-placement="top" title="Driver Assigned">
                    {{ driverOnDutyStats.waitingForStartCount }}
                  </span>
                  <span class="count orange" data-toggle="tooltip" data-placement="top" title="Driver Enroute">
                    {{ driverOnDutyStats.tripStartedCount }}
                  </span>
                  <span class="count blue" data-toggle="tooltip" data-placement="top" title="Driver Arrived">
                    {{ driverOnDutyStats.arrivedCount }}
                  </span>
                  <span class="count green" data-toggle="tooltip" data-placement="top" title="Rider picked Up">
                    {{ driverOnDutyStats.onRideCount }}
                  </span>
                </div>
                <p-multiSelect  appendTo="body" [options]="driverStatesOptions" defaultLabel="All"
                  (onChange)="onStateChange($event.value,'state')" [(ngModel)]="selectedState" [style]="{'width':'100%'}"
                  [panelStyle]="{'width': '170px'}"></p-multiSelect>
                <!-- <p-selectButton  appendTo="body" [options]="regionOptions" *ngIf="selectedState.includes('SUPPLY_REBALANCING')" defaultLabel="All"
                  (onChange)="onStateChange(selectedState, 'region')" [(ngModel)]="selectedRegionForState" [style]="{'width':'100%'}"
                  [panelStyle]="{'width': '170px'}"></p-selectButton> -->
                  <p-dropdown [options]="regionOptions" [(ngModel)]="selectedRegionForState" *ngIf="selectedState.includes('SUPPLY_REBALANCING')" defaultLabel="All" [style]="{'margin':'0px 10px'}"
                        (onChange)="onStateChange(selectedState, 'region')"></p-dropdown>
              </ng-container>
              <ng-container *ngSwitchDefault>{{ col.header }}</ng-container>
            </ng-container>
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData" [attr.id]="rowData.driverId" [ngClass]="rowData.isLate ? 'red' : null ">
        <ng-container *ngFor="let col of columns">
          <td *ngIf="col.isVisible"  [ngClass]="specialClassOnTd(col.field, rowData[col.field])"
            style="padding-top: 10px;">
            <ng-container [ngSwitch]="col.field">
              <ng-container *ngSwitchCase="'createdAt'">
                {{ rowData.createdAt | date: "MMM/dd/yy" }}
                -
                {{ rowData.createdAt | date: "shortTime" }}
              </ng-container>
              <ng-container *ngSwitchCase="'driverStateString' || 'isPaused'">

                <!-- <div *ngIf="rowData.rideDenyRequest && checkIfExist(rowData.rideDenyRequest,'isOnRide') && rowData.otherFlags.requestStatus !='PAUSED'">
                 
                  <div *ngIf="rowData.rideDenyRequest.isOnRide == true">
                    Request for support ({{rowData.rideDenyRequest.requestType}}) <span *ngIf="rowData.rideDenyRequest.requestType == 'Other'">
                      <i class="fa fa-info-circle" style="font-size: 10px; cursor: pointer;" data-toggle="tooltip"
                      data-placement="bottom" [title]="rowData.rideDenyRequest.description"></i>
                    </span>
                  </div>
                  <div *ngIf="rowData.rideDenyRequest.isOnRide == false">
                    Request for {{rowData.rideDenyRequest.pauseType == 'REPAIRING' ? 'PAUSE' : 'BREAKDOWN'}} ({{rowData.rideDenyRequest.requestType}})
                  </div>
                </div> -->

                <div data-toggle="tooltip" data-placement="top" title="{{rowData.rideId ?'Ride ID : '+rowData.rideId : ''}}">
                  {{addAnalogyName(rowData)}} <span *ngIf="rowData.driverStateString == 'SOC picture requested'">&nbsp;</span>
                  
                  <span class="requested-time" *ngIf="rowData.driverStateString == 'SOC picture requested'">{{findMinsFromNow(rowData.lowSocVerificationEventTimestamp)}} mins</span>
                 
                  
                  <span *ngIf="rowData.driverStateString == 'Request For Pause' || rowData.driverStateString == 'Request For BREAKDOWN' || rowData.driverStateString == 'Request For Support' || rowData.driverStateString == 'Request for RTH'">
                    ({{getIssueAnalogy(rowData?.rideDenyRequest.requestType)}}) 
                    <span *ngIf="rowData.rideDenyRequest.requestType == 'other'">
                      <img src="assets/fas-info.svg" class="icons-img" data-toggle="tooltip"
                      data-placement="bottom" [title]="rowData.rideDenyRequest.description">
                    </span>

                    
                    <!-- <label *ngIf="rowData.driverStateString == 'Request for RTH'">Asked at : {{rowData.lowSocVerificationEventTimestamp | date:'MMM-dd-yyyy HH:mm':'UTC+5:30' }}</label> -->
                    <label *ngIf="rowData.driverStateString != 'SOC picture requested'">&nbsp;</label> <label class="requested-time" *ngIf="rowData.driverStateString != 'SOC picture requested'">{{getRfsTime(rowData.rideDenyRequest?.createdTimestamp)}}</label>
                  </span>
                  <!-- <span *ngIf="rowData.isPaused || rowData?.otherFlags?.phantomRideDto?.phantomRideAssignedReasons"> -->
                    <span *ngIf="rowData.driverStateString == 'AUTO_PAUSED' || rowData.driverStateString == 'PAUSED' || 
                  rowData.driverStateString == 'RETURN_TO_HUB' || rowData.driverStateString == 'ENROUTE_TO_HUB' ">
                    ({{addAnalogyReason(rowData.isPaused?rowData.pauseReason : rowData.otherFlags.phantomRideDto.phantomRideAssignedReasons) }})
                    
                  </span>
                  <br>
                  <div *ngIf="rowData.driverStateString == 'RETURN_TO_HUB' && rowData.eta">
                    Assign Time : {{rowData.otherFlags.phantomRideDto.actualAssignedTimestamp | date: "shortTime"}}
                    ({{rowData.otherFlags.phantomRideDto.actualAssignedTimestamp | timediff}}) 
                    <label *ngIf="rowData.otherFlags.phantomRideDto.assignedBy !=null && rowData.otherFlags.phantomRideDto.assignedBy!=0 && rowData.otherFlags.phantomRideDto.assignedBy != '175398'">
                      | Assigned by : {{rowData.otherFlags.phantomRideDto.assignedBy}}
                     </label> 
                    <br />
                    <span *ngIf="rowData.otherFlags.phantomRideDto.assignedBy !=null" class="system-label" (click)="getAssigneeDetails(rowData.otherFlags.phantomRideDto.assignedBy)">
                      {{rowData.otherFlags.phantomRideDto.assignedBy==0 || rowData.otherFlags.phantomRideDto.assignedBy == '175398'? "SYSTEM":"OTHER"}}

                    </span>
                    <br>
                    Deadline :
                    {{ rowData.otherFlags.phantomRideDto.actualAssignedTimestamp + (rowData.otherFlags.phantomRideDto.estimatedCompletionTimestamp -rowData.otherFlags.phantomRideDto.actualAssignedTimestamp )  | date: "shortTime" }}
                  </div>
                  <div *ngIf="rowData.driverStateString == 'ENROUTE_TO_HUB' && rowData.eta">
                    Assign Time : {{rowData.otherFlags.phantomRideDto.actualAssignedTimestamp | date: "shortTime"}}
                    ({{rowData.otherFlags.phantomRideDto.actualAssignedTimestamp | timediff}}) 
                    <label *ngIf="rowData.otherFlags.phantomRideDto.assignedBy !=null && rowData.otherFlags.phantomRideDto.assignedBy!=0 && rowData.otherFlags.phantomRideDto.assignedBy != '175398'">
                     | Assigned by : {{rowData.otherFlags.phantomRideDto.assignedBy}}
                    </label> <br />

                    Deadline :
                    {{ rowData.otherFlags.phantomRideDto.actualAssignedTimestamp + (rowData.otherFlags.phantomRideDto.estimatedCompletionTimestamp -rowData.otherFlags.phantomRideDto.actualAssignedTimestamp )  | date: "shortTime" }}
                    <br /> ETA: {{rowData.eta| timediff}} <br />
                    <span *ngIf="rowData.otherFlags.phantomRideDto.assignedBy !=null" class="system-label" (click)="getAssigneeDetails(rowData.otherFlags.phantomRideDto.assignedBy)">
                      {{rowData.otherFlags.phantomRideDto.assignedBy==0 || rowData.otherFlags.phantomRideDto.assignedBy == '175398'? "SYSTEM":"OTHER"}}

                    </span>
                    <br>
                    <span *ngIf="rowData.deadlineBreach && (rowData.deadlineBreach > currentTime) ">
                      {{rowData.deadlineBreach | timediff }} late
                    </span>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'gpsTimestamp'">
                {{ rowData.location && rowData.location.timestamp ? (rowData.location.timestamp| timediff ) : ''}}
              </ng-container>
              <ng-container *ngSwitchCase="'mobile'">
                {{rowData.mobile}}
                <a style="cursor: pointer;"
                (click)="getDriverDetails(rowData.driverId)"><i class="fa fa-eye" aria-hidden="true"></i></a>
                <span *ngIf="checkPermission('LIVE_RIDE_BUTTON_CALL') && rowData.rideId && rowData.mobile"
                  style=" margin:6px 0px !important;padding: 0px !important;border-radius:50%;width:20px!important;cursor: pointer;"
                  (click)="callToDriver($event,rowData.driverId,null,true)">
                 
                  <i style="margin:2px;font-size: 13px !important;" class="fa fa-phone-square" aria-hidden="true"></i>
                </span>
                <span *ngIf="checkPermission('LIVE_RIDE_BUTTON_CALL') && rowData.mobile && !rowData.rideId"
                  style=" margin:6px 0px !important;padding: 0px !important;border-radius:50%;width:20px!important;cursor: pointer;"
                  (click)="callToDriver($event,rowData.driverId,null,false)">
                  
                  <i style="margin:2px;font-size: 13px !important;" class="fa fa-phone-square" aria-hidden="true"></i>
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="'soc'">
                <span *ngIf="rowData.soc">{{rowData.soc}}% <br><span class="smallText">( {{rowData.inputSoc}}%,
                    {{ rowData.inputSocTimestamp ? (rowData.inputSocTimestamp | timediff ) : ''}})</span></span>
              </ng-container>
              <ng-container *ngSwitchCase="'vehicleSoc'">
                <span *ngIf="rowData.batterySource && rowData.intellicarSoc">{{rowData.intellicarSoc}}% ({{getBatterySourceAnalogus(rowData.batterySource)}})</span> <br>
              </ng-container>
              <ng-container *ngSwitchCase="'vehicleNumber'">
                <span *ngIf="rowData.vehicleNumber" style="padding: 5px;">{{rowData.vehicleNumber}}</span><br/>
                <span  *ngIf="rowData.vehicleCategory =='PREMIUM'">
                  <img src="./../../../../../assets/premium-logo.png"> Premium
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="'shiftEndTimestamp'">
                {{ rowData.shiftEndTimestamp ? (rowData.shiftEndTimestamp | date:'HH:mm MMM-dd-yyyy':'UTC+5:30' ) : ''}}
              </ng-container>
              <ng-container *ngSwitchCase="'totalPausedTime'">
                {{ rowData.totalPausedTime ? getFormatedTime(rowData.totalPausedTime) : ''}}
              </ng-container>
              <ng-container *ngSwitchCase="'pauseTime'">
                {{ rowData.pauseTime ? (rowData.pauseTime | timediff ) : ''}}
              </ng-container>
              <ng-container *ngSwitchCase="'action'">
                <div style="display: flex;">
                  <!--  -->
                  <div class="icon" *ngIf = "rowData.driverStateString == 'Request For Pause' || rowData.driverStateString == 'Request For BREAKDOWN' || rowData.driverStateString == 'Request for RTH' || (rowData.rideDenyRequest && !rowData.rideDenyRequest.isOnRide && rowData.rideDenyRequest.rideId)">
                    <img src="assets/file_details.png" style="width: 20px;height: 20px;" (click)='viewRequest(rowData)' alt="file_search">
                  </div>
                  <!--  -->
                  <div *ngIf = "rowData.driverStateString == 'Request For Pause' || rowData.driverStateString == 'Request For BREAKDOWN' || rowData.driverStateString == 'Request For RTH' || (rowData.rideDenyRequest && !rowData.rideDenyRequest.isOnRide && rowData.rideDenyRequest.rideId)">
                    <img src="assets/mark_brekadown_yellow.svg" class="fa-2x icon" (click)="showConfirmPopup(rowData.rideDenyRequest,rowData)" data-toggle="tooltip"
                      data-placement="bottom" title="mark breakdown"/>
                  </div>
                  <div *ngIf="((rowData.driverStateString == 'ONLINE' || rowData.driverStateString == 'PAUSED' ) && !(rowData.otherFlags && rowData.otherFlags.phantomRideDto
                   && rowData.otherFlags.phantomRideDto.isPhantomRideAssigned && !rowData.otherFlags.phantomRideDto.isPhantomRideDropOff))">
                    <img src="assets/mark_rth.svg" class="fa-2x icon" (click)='openCreateRideDialogBox(rowData.ssoId)'
                      data-toggle="tooltip" data-placement="bottom" title="Return" />
                  </div>
                  <div *ngIf="(rowData.driverStateString == 'RETURN_TO_HUB' || rowData.driverStateString=='ENROUTE_TO_HUB')">
                    <img src="assets/cancel_rth.svg" class="fa fa-times fa-2x icon"
                      (click)='cancelPhantomRide(rowData.rideRequestId,rowData.driverId)' data-toggle="tooltip"
                      data-placement="bottom" title="Cancel" />
                  </div>
                  <div *ngIf="rowData.isPaused && rowData.pauseReason == 'BREAKDOWN'">
                    <img class="icon" src="assets/car.svg"
                      (click)='cancelBreakdown(rowData.rideRequestId,rowData.driverId)' data-toggle="tooltip"
                      data-placement="bottom" title="Remove Breakdown">
                  </div>
                  <div *ngIf="rowData.driverStateString!='OFFLINE' ">
                    <img src="assets/mark_offline.svg" class="fa-2x icon" (click)='driverOffline(rowData.driverId)' data-toggle="tooltip"
                      data-placement="bottom" title="Offline" />
                  </div>
                  <div *ngIf="rowData.driverStateString=='WAITING_FOR_START' ">
                    <img  src="assets/mark_online_green.svg" class="fa-2x icon" (click)='driverOnline(rowData.driverId)' data-toggle="tooltip" style="color: green"
                      data-placement="bottom" title="mark online" />
                  </div>
                  
                </div>
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ rowData[col.field] }}
                <!-- <div class="source-label">
                  {{rowData.driverCategory == "OUTSTATION"?'OUTSTATION':""}}
                </div> -->
                
              </ng-container>
              <ng-container *ngSwitchCase="'driverId'">
                <div>{{rowData.driverId}}</div>
                <div class="source-label">
                  {{rowData.driverCategory == "OUTSTATION"?'OUTSTATION':""}}
                </div>
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog header="Create Dummy Ride" [(visible)]="displayCreatedRide" [modal]="true" [responsive]="false" [style]="{
  minWidth: '450px',
  maxHeight: '350px',
  minHeight: '550px',
  'z-index': '999',
  'position': 'relative',
  'top': '15%'
}" [minY]="70" [maximizable]="false" [baseZIndex]="1" [dismissableMask]="true">
  <div class="flex-container" style="padding-top: 15px;padding-bottom: 15px;">
    <strong> Create Dummy Ride for the following region/location: </strong>
  </div>
  <div class="row justify-content-md-center" style="width: 100%;text-align:center;margin:0 auto;">
    <div class="col-* region definedRegion" [ngClass]="selectedRegion == 'HUB' ? 'active' : ''" (click)="select('HUB')">
      Hub</div>
    <!-- <div class="col-* region definedRegion" [ngClass]="selectedRegion == 'AIRPORT' ? 'active' : ''"
      (click)="select('AIRPORT')"> Airport</div>-->
  </div> 
  <div class="row justify-content-md-center " style="width: 100%;text-align:center;margin:0 auto;height: 200px;overflow: scroll;">
    <div *ngFor="let region of regions" class="col-*">
      <div class="region definedRegion" style="display:inline-block;vertical-align: middle;float: none;"
        [ngClass]="selectedRegion == region ? 'active' : ''" (click)="select(region)"> {{region}}</div>
    </div>
  </div>
  <div class="flex-container" style="padding-top: 15px;padding-bottom: 15px;">
    <div style="margin-right: 15px;"><label for="">Select Reason</label></div> 
     <p-dropdown [options]="reasons" [(ngModel)]="selectedReason" ngModel #cst="ngModel" required
       name="schedulerType" placeholder="Select Reason"></p-dropdown>
  </div>


  <div class="flex-container" style="padding-top: 15px;">
    <button class="btn btn-primary definedRegion" (click)="assignPhantomRide()"> Send</button>
    <button class="btn btn-danger definedRegion" (click)="openCreateRideDialogBox(driverSsoId)"> Cancel</button>
  </div>
</p-dialog>
<p-dialog header="Airport Queue" [dismissableMask]="true" [(visible)]="showAirportQueue" [blockScroll]=true [draggable]="false" [modal]="true" [responsive]="false" [style]="{
  minWidth: '700px',
  maxHeight: '400px',
  minHeight: '150px',
  'z-index': '999'
}"  [maximizable]="false" [baseZIndex]="1" (onShow)="showAirportQueueModal()" (onHide)="hideAirportQueueModal()">

<p-dropdown [options]="airports" appendTo="body" [style]="{'width':'110px'}" placeholder="Select Airport" [(ngModel)]="selectedAirport" (onChange)="getdriverQueueAtAirport()"></p-dropdown>
<button class="btn btn-primary" (click)="takeInput(-1)" style="float: right;"><i class="fa fa-plus" style="margin-right: 10px;" aria-hidden="true"></i>Add Driver on the Queue</button>
<br/><br/>

<div *ngIf="!driverList || driverList.length == 0" class="flex-container" style="padding: 8px;">
  No driver in Queue
</div>
<p-listbox [options]="driverList" [style]="{ width: '100%', height: '300px' }">
  <ng-template let-driver let-i="index" pTemplate="item">
    <div class="queued-driver-container">
      <div class="queued-driver-column">
        <div>{{driver.firstName}} {{driver.lastName}} ({{driver.driverId}})</div>
        
        <div class="queued-driver-row-2">{{driver.vehicleNumber ?? 'N/A'}} ({{driver.vehicleDisplayName ?? 'N/A'}})
        </div>
      </div>
      <div class="queued-driver-column2">
        <div g><i class="fa fa-clock-o" aria-hidden="true"></i><span
            class="queued-driver-span">Entry time: {{driver.entryTimestamp | date:'yyyy-MM-dd, HH:mm:ss'}}</span></div>
        <div class="queued-driver-row-2" [ngStyle]="{'color': isLightTheme ?'#000': '#fff'}"><i class="fa fa-car"
            aria-hidden="true"></i><span class="queued-driver-span">Source of Entry: {{driver.airportEntryReason ?
            entryReasons[driver.airportEntryReason] : 'N/A'}}</span></div>
      </div>
    </div>
  </ng-template>
</p-listbox>
</p-dialog>

<p-dialog header="Enter punch Id " [dismissableMask]="true" [(visible)]="showpunchIdInput" [modal]="true" [responsive]="false" [style]="{
  minWidth: '450px',
  maxHeight: '350px',
  minHeight: '350px',
  'z-index': '999'
}" [minY]="70" [maximizable]="false" [baseZIndex]="1" (onHide)="cancelPunchIdEnterAction()">
<div>
  <div style="margin: 0 10px;"><input  [(ngModel)]="punchId" placeholder="Enter punch Id to add" pInputText></div>
  <div style=" float: right;"><button class="btn btn-primary" (click)="addDriverInQueue()"> ADD</button></div>
</div>
</p-dialog>
<p-dialog header="Dispatcher" [(visible)]="displayDispatcherContact" [modal]="true" [responsive]="false" [style]="{
  maxWidth: '450px',
  minWidth: '400px',
  maxHeight: '550px',
  minHeight: '370px',
  'z-index': '999'
}" [minY]="70" [maximizable]="false" [baseZIndex]="1" (onHide)="hide()">
  <div class="form-group">
    <label for="">Dispatcher List</label>
    <select name="dispatcherList" id="" class='form-control' [(ngModel)]="selectedDispatchNumber" ngModel required
      #p='ngModel'>
      <option *ngFor="let client of dispatcherList">{{client}}</option>
      <option>Other</option>
    </select>
    <br>
    <br>
    <input *ngIf="selectedDispatchNumber=='Other'" pInputText type="text" class="form-control"
      style="display: flex;width:100%;margin:auto" placeholder="Mobile No" [(ngModel)]="manualNumber" />
    <div class="alert alert-danger" *ngIf="p.touched && p.invalid">
      number is required
    </div>
  </div>
  <div class="col pt-2" style="display: flex;margin: auto;width: 100%;">
    <div style="display: flex;margin: 10px auto;">
      <button class="btn btn-primary" (click)="updateDispatcherNum()" [disabled]="load">
        Update
      </button>
    </div>
  </div>
</p-dialog>
<p-dialog [(visible)]="showReviewPause" [dismissableMask]="true" [modal]="true" [responsive]="false" [style]="{
  minWidth: '450px',
  maxHeight: '550px',
  minHeight: '350px',
  'z-index': '999',
  'position': 'relative',
  'bottom': '15%'
}" [minY]="70" [maximizable]="false" [baseZIndex]="1">
<p-header>
    Issue Type : {{getIssueAnalogy(driverData?.rideDenyRequest.requestType)}}
</p-header>
<div>
  <app-review-pause-request *ngIf="showReviewPause" (closeDialog)="closeDialog()" [driverData]="driverData"></app-review-pause-request>
</div>
</p-dialog>
<p-dialog [(visible)]="diplayConfirmPopup" [dismissableMask]="true" [modal]="true" [responsive]="false" [style]="{
  maxWidth: '500px',
  maxHeight: '350px',
  minHeight: '350px',
  'z-index': '999'
}" [minY]="70" [maximizable]="false" [baseZIndex]="1">
<div style="font-size: 1rem; padding: 20px;">
  Do you confirm breakdown request?
  <div class="flex_row mt-4" style="gap: 20px;justify-content: center">
    <button class="action-btn" (click)="markBreakdown(currentRideDenyRequestData)">Yes</button>
    <button class="action-btn" (click)="hideConfirmPopup()">No</button>
  </div>
</div>
</p-dialog>
