<div class="container-fluid header-nav sticky-top">
  <!-- <div class="row justify-content-space-between"> -->
  <div id="mySidenav" class="sidenav" [ngStyle]="{'width':collapse==false?'300px':'0px'}">
    <!-- <a class="closebtn close" (click)="closeNav(null)">&times;</a> -->

    <div class="container" style="overflow: hidden;margin: 10px;">
      <div class="row" style="width: 500px;">
        <div class="col-6">
          <span class="tile">Dispatch</span>
          <div class="list-group" id="list-tab" role="tablist">
            
            <a *ngIf="checkPermission('MENU_LIVE_RIDES')" class="list-group-item list-group-item-action"
              id="list-ride-list" data-toggle="list" role="tab" aria-controls="liverides"
              [routerLink]="['/rides/liverides/beta']" routerLinkActive="active" (click)="closeNav(null)">Live Rides</a>
            <!-- <a *ngIf="checkPermission('MENU_LIVE_RIDES')" class="list-group-item list-group-item-action"
              id="list-home-list" data-toggle="list" role="tab" aria-controls="home" [routerLink]="['/rides/liverides']"
              [routerLink]="active" (click)="closeNav(null)"> Old Live Rides</a> -->
            <a *ngIf="checkPermission('MENU_PAST_RIDES')" class="list-group-item list-group-item-action"
              id="list-profile-list" data-toggle="list" role="tab" aria-controls="profile"
              [routerLink]="['/rides/pastrides']" routerLinkActive="active" (click)="closeNav(null)">Past Rides</a>
              <a *ngIf="checkPermission('MENU_LIVE_MAP_CLUSTER')" class="list-group-item list-group-item-action"
              id="list-profile-list" data-toggle="list" role="tab" aria-controls="livemap" [routerLink]="['/livemap']"
              routerLinkActive="active" (click)="closeNav('/livemap')">Live
              Map Cluster</a>
            <!-- <a *ngIf="checkPermission('MENU_LIVE_MAP')" class="list-group-item list-group-item-action"
              id="list-profile-list" data-toggle="list" role="tab" aria-controls="livemap" [routerLink]="['/livemapbeta']"
              routerLinkActive="active" (click)="closeNav('/livemapbeta')">Live Map</a>   -->
            <a *ngIf="checkPermission('MENU_DRIVERS_ON_DUTY')" class="list-group-item list-group-item-action"
              id="list-settings-list" data-toggle="list" role="tab" aria-controls="settings"
              [routerLink]="['/driver-on-duty']" routerLinkActive="active" (click)="closeNav('/driver-on-duty')">Drivers on Duty</a>

            <!-- <a class="list-group-item list-group-item-action" id="list-settings-list" data-toggle="list"  role="tab" aria-controls="settings" routerLink='/riders' (click)="closeNav(null)" >Riders</a> -->
            <a *ngIf="checkPermission('MENU_SCHEDULE_RIDES')" class="list-group-item list-group-item-action"
              id="list-settings-list" data-toggle="list" role="tab" aria-controls="settings"
              [routerLink]="['/rides/schedulerides']" routerLinkActive="active" (click)="closeNav(null)">Scheduled Rides</a>
              <a *ngIf="checkPermission('MENU_HOME_CHARGING')"  class="list-group-item list-group-item-action "
              id="list-home-list" data-toggle="list" role="tab" aria-controls="home" routerLink='/home-charging'
              (click)="closeNav('/home-charging')">Home Charging</a>
              <a  *ngIf="checkPermission('MENU_BREAKDOWN_MANAGEMENT')"  class="list-group-item list-group-item-action "
              id="list-home-list" data-toggle="list" role="tab" aria-controls="home" routerLink='/sos-management'
              (click)="closeNav('/sos-management')">Breakdown Management</a>
              <a *ngIf="checkPermission('MENU_SLOTS_VISUAL')" class="list-group-item list-group-item-action"
              id="list-settings-list" data-toggle="list" role="tab" aria-controls="settings"
              [routerLink]="['/rides/slotsVisual']" routerLinkActive="active" (click)="closeNav('/slotsVisual')">Slots Visualization</a>
           
              <!-- <a  class="list-group-item list-group-item-action"
              id="list-profile-list" data-toggle="list" role="tab" aria-controls="settings"
              [routerLink]="['/rides/slotsVisual']" routerLinkActive="active" (click)="closeNav(null)">Slots Visualization</a> -->
          </div>
        </div>
      </div>
      <div class="row" style="width: 500px;">
        <div class="col-6">
          <br />
          <span class="tile">Manage</span>
          <div class="list-group" id="list-tab" role="tablist">
            <a *ngIf="checkPermission('MENU_VEHICLE')" class="list-group-item list-group-item-action "
              id="list-home-list" data-toggle="list" role="tab" aria-controls="home" routerLink='/vehicle'
              (click)="closeNav('/vehicle')">Vehicle</a>
            <a *ngIf="checkPermission('MENU_DRIVER')" class="list-group-item list-group-item-action"
              id="list-profile-list" data-toggle="list" role="tab" aria-controls="profile" routerLink='/driver'
              (click)="closeNav(null)">Driver</a>
            <a *ngIf="checkPermission('MENU_RIDER')" class="list-group-item list-group-item-action " id="list-home-list"
              data-toggle="list" role="tab" aria-controls="home" routerLink='/riders' (click)="closeNav(null)">Rider</a>
              <a *ngIf="checkPermission('MENU_RIDER_NEW')" class="list-group-item list-group-item-action " id="list-home-list"
              data-toggle="list" role="tab" aria-controls="home" routerLink='/rider-new' (click)="closeNav('/rider-new')">Rider 2.0</a>
            <!-- <a *ngIf="checkPermission('MENU_CUSTOMER_SUPPORT')" class="list-group-item list-group-item-action " id="list-home-list"
              data-toggle="list" role="tab" aria-controls="home" routerLink='/customer-support' (click)="closeNav(null)">Customer Support</a> -->
            <a *ngIf="checkPermission('MENU_PAYMENTS')" class="list-group-item list-group-item-action "
              id="list-home-list" data-toggle="list" role="tab" aria-controls="home" routerLink='/payments'
              (click)="closeNav(null)">Payments</a>
            <a *ngIf="checkPermission('MENU_REPORTS')" class="list-group-item list-group-item-action "
              id="list-home-list" data-toggle="list" role="tab" aria-controls="home" routerLink='/reports'
              (click)="closeNav('/reports')">Reports</a>
            <a *ngIf="checkPermission('MENU_JOURNEY')" class="list-group-item list-group-item-action"
              id="journey-details" data-toggle="list" role="tab" aria-controls="journery-details"
              routerLink='/journey-details' (click)="closeNav('/journey-details')">Driver-Vehicle History</a>
            <a *ngIf="checkPermission('MENU_OVERSPEEDING')" class="list-group-item list-group-item-action"
              id="list-profile-list" data-toggle="list" role="tab" aria-controls="profile" routerLink='/overspeeding'
              (click)="closeNav(null)">Overspeeding</a>
              <a *ngIf="checkPermission('MENU_OVERWRITING_FARE')" class="list-group-item list-group-item-action"
              id="list-profile-list" data-toggle="list" role="tab" aria-controls="profile"
              routerLink='/overwriting_fare' (click)="closeNav('/overwriting_fare')">Custom Pricing</a>
            <a *ngIf="checkPermission('MENU_SETTING')" class="list-group-item list-group-item-action"
              id="list-profile-list" data-toggle="list" role="tab" aria-controls="profile" routerLink='/setting'
              (click)="closeNav('/setting')">Settings</a>
              <a *ngIf="checkPermission('MENU_SLOTS')" class="list-group-item list-group-item-action"
              id="list-profile-list" data-toggle="list" role="tab" aria-controls="profile" routerLink='/slots'
              (click)="closeNav('/slots')">Slots</a>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div *ngIf="isLoggedIn" class="sidenav notification-nav" [ngStyle]="{'width': notificationCollapse === false ? '355px' : '0px'}">
    <div class="notification-container">
      <div class="notification-cont-header">
        <h2 class="notification-title">Notification</h2>
        <img style="cursor: pointer;"(click)="handleNotificationCollapse()" src="../../../../assets/notification-close.svg"/>
      </div>
     

        <div *ngFor="let card of notificationData; let i = index" class="notification-card sos"  [attr.data-id]="card.id" >
         <div class="close-notification-cont" (click)="updateNotifications('CLOSED',false,card.id,i)">
          <img  src="../../../../assets/notification-close.svg" style="border-radius: 50%;" class="close-notification"/>
          <span>close</span></div> 
            <div class="notification-header">
              <div class="notfi-alert-box">
                <img class="warn-icon" src="../../../../assets/notf-warn.svg"/>
                <div class="alert-cont">
                  <span class="alert-type">{{card.type?card.type:card.data.type}}</span>
                  <p class="alert-message">{{card.data.message}}</p>
                </div>
               
              </div>
                <span class="time-ago">{{convertToReadableTime(card.createdAt)}}  Ago</span>
            </div>
            <div class="notification-body">
               
                <div class="details-group">
                    <div *ngIf="card.type != 'HOME_CHARGING_ALERTS'">
                        <strong style="color: rgba(255, 255, 255, 0.50);"><img class="ride-icon" src="../../../../assets/notf-ride-icon.svg"> Rider Details</strong>
                        <p>{{card.data.riderId?card.data.riderId:'-'}} | {{card.data.riderName?card.data.riderName:'-'}}</p>
                    </div>
                    <div>
                        <strong style="color: rgba(255, 255, 255, 0.50);">     <svg class="driver-icon" xmlns="http://www.w3.org/2000/svg" width="11" height="12"
                          viewBox="0 0 11 12" fill="none">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M8.20161 3.66462C9.06371 3.48665 9.60397 3.2845 9.60397 2.99978C9.60397 2.68805 8.9641 2.41147 7.96093 2.22631C7.7453 1.45753 7.3281 0.684061 7.00933 0.285607C6.78667 0.00668988 6.40228 -0.0800324 6.08351 0.0793491L5.43661 0.4028C5.22567 0.508273 4.97722 0.508273 4.76627 0.4028L4.11937 0.0793491C3.80061 -0.0800324 3.41622 0.00668988 3.19355 0.285607C2.87714 0.684061 2.45759 1.45753 2.24195 2.22631C1.24113 2.41147 0.601262 2.68805 0.601262 2.99978C0.601262 3.2951 1.03249 3.41722 1.95128 3.60004L2.2777 3.69576L2.25001 4.12488C2.25001 4.92054 2.56608 5.6836 3.12869 6.24621C3.6913 6.80882 4.45437 7.1249 5.25002 7.1249C6.04567 7.1249 6.80874 6.80882 7.37135 6.24621C7.93396 5.6836 8.25003 4.92054 8.25003 4.12488L8.20161 3.66462ZM3.37501 3.92199C3.90744 3.97179 4.49055 4.0006 5.10379 4.0006C5.83139 4.0006 6.51658 3.96188 7.12503 3.89262V4.12488C7.12503 4.62217 6.92748 5.09908 6.57585 5.45071C6.22422 5.80235 5.7473 5.99989 5.25002 5.99989C4.75274 5.99989 4.27582 5.80235 3.92419 5.45071C3.57256 5.09908 3.37501 4.62217 3.37501 4.12488V3.92199ZM5.25002 9.73561L7.4862 7.4999C9.16175 7.57255 10.5 8.94365 10.5 10.6356C10.5 10.9974 10.3563 11.3445 10.1004 11.6003C9.84459 11.8562 9.49757 11.9999 9.13574 11.9999H1.3643C1.00247 11.9999 0.655451 11.8562 0.399595 11.6003C0.143739 11.3445 0 10.9974 0 10.6356C0 8.94365 1.33829 7.57255 3.01384 7.4999L5.25002 9.73561Z"
                              fill="#2C66E3" />
                      </svg> Driver Details</strong>
                        <p>{{card.data.driverId?card.data.driverId:'-'}} | {{card.data.driverName?card.data.driverName:'-'}}</p>
                    </div>
                </div>
                <div *ngIf="card.showResolve" class="resolve-form">
                  <div class="flex_column gap-15">
                    <span class="f-12" style="color: rgba(255, 255, 255, 1);">Reason</span>
                    <p-dropdown (onChange)="onReasonChange($event)" [options]="closeReasons" [style]="{'width':'100%','height':'33px'}"
                    placeholder="Select reason for closing alert" style="margin-bottom: 13px;" [(ngModel)]="selectedCloseReason.value" class="abc" ></p-dropdown>
                  </div>

                  <div class="flex_column gap-15">
                    <span class="f-12" style="color: rgba(255, 255, 255, 1);">Remarks</span>
                    <textarea style="background: transparent;
                    font-size: 12px;
                    color: #fff;
                    border-radius: 4px;
                    padding: 7px;
                    height: 75px;" placeholder="Enter remarks for closing " [(ngModel)]="closeRemarks"></textarea>
                  </div>
                </div>
            </div>
            <div *ngIf="!card.showResolve && !card.isAlertClosed && card.type == 'SOS'" class="notification-footer">
                <button *ngIf="card['data']['riderId']" class="check-ride-btn" (click)="checkRide(card['data']['riderId'])"><img class="btn-icon" src="../../../../assets/notf-car.svg"> Check Ride</button>
                <button (click)="toggleResolve(i)" class="resolve-btn">&#10003; Resolve</button>
            </div>

            <div *ngIf="!card.showResolve && !card.isAlertClosed && card.type == 'HOME_CHARGING_ALERTS'" class="notification-footer">
              <button class="check-ride-btn" (click)="callDriver(card.data.driverId)"><img class="btn-icon" src="../../../../assets/notf-car.svg"> Call Driver</button>
              <!-- <button (click)="toggleResolve(i)" class="resolve-btn">&#10003; Resolve</button> -->
          </div>

            <div  *ngIf="card.showResolve && !card.isAlertClosed" class="notification-footer">
              <button (click)="cancelResolve(i)" class="check-ride-btn" style="border: 0.5px solid #FF3B30;
              color: #FF3B30;">&#10005; Cancel</button>
              <button class="resolve-btn" [ngClass]="(closeRemarks.length >=2 && selectedCloseReason.value.length >=1)?'resolve-enabled':'resolve-disabled'" (click)="(closeRemarks.length >=2 && selectedCloseReason.value.length >=1)? updateNotifications('RESOLVED',false,card.id,i):''"> &#10003; Resolve</button>
          </div>

          <div *ngIf="card.isAlertClosed" class="notification-footer" style="font-size: 12px;display: flex;justify-content: flex-end;    border-top: 1px solid white;
          padding-top: 20px;">
            <img style="width: 21px;height: 21px;margin-right: 15px;" src="../../../../assets/notfi-tick.svg"><span>Alert Closed Succesfully</span>
          </div>

        </div>

        <!-- <div class="notification-card charging">
          <div class="notification-header">
            <div class="notfi-alert-box">
              <img class="warn-icon" src="../../../../assets/notf-warn.svg"/>
              <div class="alert-cont">
                <span class="alert-type">SOS Alert</span>
                <p class="alert-message">R/W not connected</p>
              </div>
             
            </div>
              <span class="time-ago">2 Min Ago</span>
          </div>
            <div class="notification-body">
               
                <div class="details-group">
                  <div>
                    <strong>     <svg class="driver-icon" xmlns="http://www.w3.org/2000/svg" width="11" height="12"
                      viewBox="0 0 11 12" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M8.20161 3.66462C9.06371 3.48665 9.60397 3.2845 9.60397 2.99978C9.60397 2.68805 8.9641 2.41147 7.96093 2.22631C7.7453 1.45753 7.3281 0.684061 7.00933 0.285607C6.78667 0.00668988 6.40228 -0.0800324 6.08351 0.0793491L5.43661 0.4028C5.22567 0.508273 4.97722 0.508273 4.76627 0.4028L4.11937 0.0793491C3.80061 -0.0800324 3.41622 0.00668988 3.19355 0.285607C2.87714 0.684061 2.45759 1.45753 2.24195 2.22631C1.24113 2.41147 0.601262 2.68805 0.601262 2.99978C0.601262 3.2951 1.03249 3.41722 1.95128 3.60004L2.2777 3.69576L2.25001 4.12488C2.25001 4.92054 2.56608 5.6836 3.12869 6.24621C3.6913 6.80882 4.45437 7.1249 5.25002 7.1249C6.04567 7.1249 6.80874 6.80882 7.37135 6.24621C7.93396 5.6836 8.25003 4.92054 8.25003 4.12488L8.20161 3.66462ZM3.37501 3.92199C3.90744 3.97179 4.49055 4.0006 5.10379 4.0006C5.83139 4.0006 6.51658 3.96188 7.12503 3.89262V4.12488C7.12503 4.62217 6.92748 5.09908 6.57585 5.45071C6.22422 5.80235 5.7473 5.99989 5.25002 5.99989C4.75274 5.99989 4.27582 5.80235 3.92419 5.45071C3.57256 5.09908 3.37501 4.62217 3.37501 4.12488V3.92199ZM5.25002 9.73561L7.4862 7.4999C9.16175 7.57255 10.5 8.94365 10.5 10.6356C10.5 10.9974 10.3563 11.3445 10.1004 11.6003C9.84459 11.8562 9.49757 11.9999 9.13574 11.9999H1.3643C1.00247 11.9999 0.655451 11.8562 0.399595 11.6003C0.143739 11.3445 0 10.9974 0 10.6356C0 8.94365 1.33829 7.57255 3.01384 7.4999L5.25002 9.73561Z"
                          fill="#2C66E3" />
                  </svg> Driver Details</strong>
                    <p>1958224 | Driver Name</p>
                </div>
                </div>
            </div>
            <div class="notification-footer">
                <button class="call-driver-btn"><img  class="btn-icon" src="../../../../assets/notf-call.svg"> Call Driver</button>
                <button class="alert-detail-btn"><img  class="btn-icon" src="../../../../assets/notf-vis.svg">Alert Detail</button>
            </div>
        </div>

        <div class="notification-card alert">
            <div class="notification-header">
                <span class="alert-type">Alert Raised</span>
                <span class="time-ago">8 Min Ago</span>
            </div>
            <div class="notification-body">
                <p class="alert-message">IVR not connected</p>
                <div class="details-group">
                    <div>
                        <strong>Rider Details</strong>
                        <p>1958224 | Rider Name</p>
                    </div>
                </div>
            </div>
            <div class="notification-footer">
                <button class="check-ride-btn">Check Ride</button>
            </div>
        </div> -->
    </div>
</div>
  <div class='d-flex justify-content-between'>
    <div class='d-flex justify-content-around'>

      <span style="font-size:20px;cursor:pointer;color: white;" class="col-xs-4 ml-3 mb-1" (click)="openNav()"
        *ngIf='appService.isValid'> &#9776;&nbsp;</span>
      <!-- <span style="font-size:20px;cursor:pointer" class="col-xs-4 ml-3"(click)="openNav()"
           *ngIf='!appService.isValid'> </span> -->
      <div class='ml-3' style="padding-top: 4px;">
        <img src="../../../../assets/logo_1x.png" alt="" style="width: 150x;height:20px;">
      </div>

      <div>
        <div *ngIf='appService.isValid' class="form-group pl-4" style="margin: auto;">
          <!-- multiselect with multiple selection -->
          <p-multiSelect appendTo="body" [options]="appService.zonesList" [(ngModel)]="appService.selectedZones"
            *ngIf="isEnableMultiSelect" (onChange)="onServiceRegionChange($event.value)" [style]="{'width':'100%'}"
            [panelStyle]="{'width': '170px'}"></p-multiSelect>

          <!-- <select (change)="onServiceRegionChange($event.target.value)" *ngIf="!isEnableMultiSelect"  [(ngModel)]="appService.selectedZone" name="" id="" class='form-control'>
            <option *ngFor="let zone of appService.zonesList" [value]="zone.value">
              {{zone.label}}
            </option>
        </select> -->


        <p-dropdown [options]="appService.zonesList"  *ngIf="!isEnableMultiSelect" ngDefaultControl  appendTo="body" [style]="{'width':'100px'}" placeholder="Select Zone" [(ngModel)]="appService.selectedZone" (onChange)="onServiceRegionChange($event.value)"></p-dropdown>



        </div>
      </div>

    </div>

    <div style="margin-top: auto; margin-bottom: auto;">
      <span *ngIf="isLoggedIn" style="padding-right: 20px;cursor: pointer;position: relative;" (click)="handleNotificationCollapse()"><img src="../../../../assets/notfi-count-icon.svg"><span *ngIf="notificationCount" class="notfi-count">{{notificationCount}}</span></span>
      <span *ngIf="appService.isValid && appService.name" style="padding-right: 20px;font-size: 16px;"> Hello,
        {{appService.name}} &nbsp; |</span>
      <a class='logout' *ngIf='appService.isValid' (click)='logout()' (click)='closeNav(null)'>
        <i class="fa fa-sign-out" aria-hidden="true" style="padding-right: 2px;"></i> Logout</a>
    </div>
  </div>




</div>
<p-dialog header="Create ticket" [(visible)]="displayCreateTicket" [draggable]="false">
  <app-create-ticket *ngIf="displayCreateTicket"></app-create-ticket>
</p-dialog>
<!-- 
<button *ngIf='appService.isValid' class="btn btn-primary widget" (click)="openCreateTicketDailog()"><i
    class="fa fa-ticket"></i></button> -->

<p-toast>
  <ng-template let-message pTemplate="message">
    <div class="stack">
      <p class="head">
        <ng-container>
          <i class="pi pi-check" *ngIf="message.severity == 'success' "></i>
          <i class="pi pi-info-circle" *ngIf="message.severity == 'info' "></i>
        </ng-container>
        &nbsp;
        {{ message.summary }}
      </p>
      <p *ngIf="message.detail" class="detail">
        {{ message.detail }}
      </p>
      <ng-container *ngIf="message.data">
        <p *ngFor="let detail of message.data" class="detail">
          {{ detail }}
        </p>
      </ng-container>
    </div>
  </ng-template>
</p-toast>